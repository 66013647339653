import SortListIcon from '@/assets/sort-list-icon.svg';
import { withDfMediaQuery } from '@/utils/dfMediaQuery';
import { ArrowDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex } from 'antd';
import { useState } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';
import { useNavigate } from 'react-router';
import { CreateNewSceneCard } from '../components/CreateNewSceneCard';
import { Scene } from '../components/Scene';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from '../components/Scene/__generated__/SceneFragment.graphql';
import { MonitorAppPathname } from '../constants';
import { useMonitorContext } from '../MonitorContext';
import { useMonitorAppSubHeaderAuxControls } from '../useMonitorAppSubHeaderAuxControls';
import {
  TeamScenesContainer,
  TeamScenesListContainer,
  TeamScenesLoadMoreCtaContainer,
  TeamScenesSortingListItem,
} from './styles';
import type { TeamScenesFragment$key } from './__generated__/TeamScenesFragment.graphql';

const ITEMS_PER_PAGE = 10;

export const TeamScenesFragment = graphql`
  fragment TeamScenesFragment on VMSPlusScenesData
  @argumentDefinitions(
    only_favorites: { type: "Boolean", defaultValue: false }
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
    app_id: { type: "Int" }
    customer_id: { type: "Int" }
  )
  @refetchable(queryName: "TeamScenesPaginationQuery") {
    scenes(
      first: $count
      after: $cursor
      filters: { onlyFavorites: $only_favorites }
      appId: $app_id
      customerId: $customer_id
    ) @connection(key: "VMSPlusConfig_scenes") {
      edges {
        node {
          id
          ...SceneFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const TeamScenes = ({ isMobile }: { isMobile: boolean }) => {
  const {
    customerId,
    monitorAppQueryData,
    appId,
    setSelectedSceneRef,
    setSelectedChannels,
  } = useMonitorContext();
  const [showOnlyFavorite, setShowOnlyFavorite] = useState(false);
  const {
    data: fragmentData,
    loadNext,
    refetch,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    TeamScenesFragment,
    monitorAppQueryData.vmsPlusConfig?.scenesData as TeamScenesFragment$key,
  );
  const scenes = fragmentData.scenes?.edges;
  const navigate = useNavigate();

  const handleNewSceneClick = () => {
    setSelectedSceneRef(undefined);
    setSelectedChannels(undefined);
    navigate(`${MonitorAppPathname}/active-scene`);
  };

  const toggleSetShowOnlyFavorite = () => {
    setShowOnlyFavorite((prev) => !prev);
    refetch({
      count: ITEMS_PER_PAGE,
      only_favorites: !showOnlyFavorite,
      app_id: appId,
      customer_id: customerId,
    });
  };

  useMonitorAppSubHeaderAuxControls(
    <Flex gap={isMobile ? 10 : 20}>
      <Button onClick={handleNewSceneClick}>+ New Scene</Button>
      <Dropdown
        menu={{
          items: [
            {
              key: '0',
              label: (
                <TeamScenesSortingListItem>
                  {showOnlyFavorite ? 'Show all' : 'Show starred'}
                </TeamScenesSortingListItem>
              ),
              onClick: toggleSetShowOnlyFavorite,
            },
          ],
        }}
        trigger={['click']}>
        <Button icon={<img src={SortListIcon} />} type="text" ghost />
      </Dropdown>
    </Flex>,
    [showOnlyFavorite],
  );

  const handleTileSelect = (
    sceneRef: SceneFragment$key,
    sceneData: SceneFragment$data,
  ) => {
    setSelectedSceneRef(sceneRef);
    setSelectedChannels(sceneData.channelIDs as string[]);
    navigate(`${MonitorAppPathname}/active-scene`);
  };

  const handleLoadMore = () => {
    loadNext(ITEMS_PER_PAGE, {
      UNSTABLE_extraVariables: {
        app_id: appId,
        customer_id: customerId,
        only_favorites: showOnlyFavorite,
      },
    });
  };

  if (!scenes?.length) {
    return <CreateNewSceneCard />;
  }

  return (
    <TeamScenesContainer>
      <TeamScenesListContainer>
        {scenes.map((scene) => (
          <Scene
            key={scene?.node?.id}
            sceneKey={scene?.node as SceneFragment$key}
            appId={appId}
            customerId={customerId}
            onSelect={handleTileSelect}
            sceneConnectionData={{
              id: monitorAppQueryData.vmsPlusConfig?.scenesData?.__id as string,
              key: 'VMSPlusConfig_scenes',
            }}
          />
        ))}
      </TeamScenesListContainer>
      <TeamScenesLoadMoreCtaContainer>
        {hasNext && (
          <Button
            type="link"
            icon={<ArrowDownOutlined />}
            onClick={handleLoadMore}
            loading={isLoadingNext}>
            Load more
          </Button>
        )}
      </TeamScenesLoadMoreCtaContainer>
    </TeamScenesContainer>
  );
};

export default withDfMediaQuery(TeamScenes);
