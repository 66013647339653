import LoadingSpinner from '@/components/LoadingSpinner';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useSelector } from '@umijs/max';
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { usePaginationFragment } from 'react-relay';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from '../components/Scene/__generated__/SceneFragment.graphql';
import { useMonitorContext } from '../MonitorContext';
import { useSceneAddActions, useSceneUpdateActions } from '../MonitorMutations';
import { TeamScenesFragment } from '../TeamScenes';
import type { TeamScenesFragment$key } from '../TeamScenes/__generated__/TeamScenesFragment.graphql';
import { useMonitorAppSubHeaderAuxControls } from '../useMonitorAppSubHeaderAuxControls';
import { getSceneName } from '../utils';
import { ChannelsSelection } from './ChannelsSelection';
import { SceneTimeline } from './SceneTimeline';
import { ActiveSceneContainer, StyledSider } from './styles';

const ActiveSceneContent = () => {
  const {
    appId,
    selectedChannels,
    selectedSceneData,
    isSidebarHidden,
    toggleSidebarVisibility,
    monitorAppQueryData,
    setSelectedSceneRef,
  } = useMonitorContext();
  const timelinePlayerRef = useRef();
  const { token } = theme.useToken();
  const { isMobile } = useDfMediaQuery();

  const getAnchorTimestamp = () => {
    // @ts-expect-error
    let timestamp = timelinePlayerRef.current?.state.currentPlayTime;
    if (timestamp) {
      // db doesn't like floats...
      timestamp = Math.floor(timestamp) + '';
    }

    return timestamp;
  };

  const { ch } = useSelector((state) => ({
    // @ts-expect-error
    ch: state.locations.ch,
  }));
  const [form] = Form.useForm();

  const [saveSceneModalOpen, setSaveSceneModalOpen] = useState(false);

  const areChannelsChangedInScene = useMemo(() => {
    if (!!selectedSceneData) {
      const selectedSceneDataSet = new Set(selectedSceneData.channelIDs);
      const selectedChannelsSet = new Set(selectedChannels ?? []);
      // Compare if both sets contain the same values
      return (
        selectedSceneDataSet.size !== selectedChannelsSet.size ||
        [...selectedSceneDataSet].some(
          (value) => !selectedChannelsSet.has(value),
        )
      );
    }

    return (selectedChannels ?? []).length > 0;
  }, [selectedChannels, selectedSceneData]);

  const viewingExistingScene = !!selectedSceneData;

  const { data: fragmentData } = usePaginationFragment(
    TeamScenesFragment,
    monitorAppQueryData.vmsPlusConfig?.scenesData as TeamScenesFragment$key,
  );

  const newSceneNode = useRef(
    fragmentData.scenes?.edges?.[0]?.node as SceneFragment$key,
  );

  useEffect(() => {
    newSceneNode.current = fragmentData.scenes?.edges?.[0]
      ?.node as SceneFragment$key;
  }, [fragmentData.scenes?.edges]);

  useMonitorAppSubHeaderAuxControls(
    <Flex gap={20}>
      {areChannelsChangedInScene && (
        <Button onClick={() => setSaveSceneModalOpen(true)} type="primary">
          {viewingExistingScene ? 'Save Scene' : 'Create Scene'}
        </Button>
      )}
      {isSidebarHidden ? (
        <MenuFoldOutlined
          onClick={toggleSidebarVisibility}
          style={{ fontSize: '24px' }}
        />
      ) : (
        <MenuUnfoldOutlined
          onClick={toggleSidebarVisibility}
          style={{ fontSize: '24px' }}
        />
      )}
    </Flex>,
    [areChannelsChangedInScene, isSidebarHidden],
  );

  const { updateSceneNameAndChannels } = useSceneUpdateActions({
    scene: selectedSceneData as SceneFragment$data,
    appId,
  });

  const { addScene } = useSceneAddActions({
    appId,
    sceneConnectionData: {
      id: monitorAppQueryData.vmsPlusConfig?.scenesData?.__id as string,
      key: 'VMSPlusConfig_scenes',
    },
  });

  const handleSceneCreateUpdateViaModal = (updatedSceneName: string) => {
    if (viewingExistingScene) {
      updateSceneNameAndChannels(
        updatedSceneName,
        selectedChannels as string[],
        getAnchorTimestamp(),
      );
    } else {
      addScene({
        channelIds: selectedChannels as string[],
        name: updatedSceneName,
        anchorTimeStamp: getAnchorTimestamp(),
        onSceneAdded: () => {
          setSelectedSceneRef(newSceneNode.current);
        },
      });
    }
  };

  const saveSceneModalContent = (
    <Modal
      open={saveSceneModalOpen}
      onCancel={() => setSaveSceneModalOpen(false)}
      title={viewingExistingScene ? 'Save Scene' : 'Create Scene'}
      footer={null}
      centered>
      <Form
        form={form}
        onFinish={(values) => {
          handleSceneCreateUpdateViaModal(values.sceneName);
          setSaveSceneModalOpen(false);
        }}
        initialValues={{
          sceneName: viewingExistingScene
            ? selectedSceneData?.name ??
              getSceneName(selectedSceneData?.channelIDs as string[], ch)
            : 'New Scene',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography.Text>
            Save this scene for quick viewing later under the ‘Team Scenes’ tab.
          </Typography.Text>
          <Form.Item name="sceneName" style={{ marginBottom: 0 }}>
            <Input />
          </Form.Item>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button
              onClick={() => setSaveSceneModalOpen(false)}
              style={{ width: '100%' }}>
              Discard Changes
            </Button>
            <Tooltip
              title={
                selectedChannels?.length === 0 ? 'No cameras selected' : ''
              }
              color={token.colorError}>
              <Button
                disabled={selectedChannels?.length === 0}
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}>
                {viewingExistingScene ? 'Save Scene' : 'Create Scene'}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Form>
    </Modal>
  );

  return (
    <ActiveSceneContainer>
      <SceneTimeline timelinePlayerRef={timelinePlayerRef} />
      <StyledSider
        trigger={null}
        breakpoint="md"
        collapsedWidth="0"
        collapsible={true}
        collapsed={isSidebarHidden}
        width={isMobile ? 'calc(100vw - 15px)' : 282}>
        <ChannelsSelection />
      </StyledSider>
      {saveSceneModalContent}
    </ActiveSceneContainer>
  );
};

const ActiveScene = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ActiveSceneContent />
    </Suspense>
  );
};

export default ActiveScene;
