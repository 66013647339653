import { getCurrentCustomerID } from '@/utils/utils';
import { ConnectionHandler, graphql, useMutation } from 'react-relay';
import type { SceneFragment$data } from './components/Scene/__generated__/SceneFragment.graphql';
import { getCurrentTimestamp } from './utils';
import type { MonitorMutations_SceneConnectionAdd_Mutation } from './__generated__/MonitorMutations_SceneConnectionAdd_Mutation.graphql';
import type { MonitorMutations_SceneConnectionRemove_Mutation } from './__generated__/MonitorMutations_SceneConnectionRemove_Mutation.graphql';
import type { MonitorMutations_SceneRemove_Mutation } from './__generated__/MonitorMutations_SceneRemove_Mutation.graphql';
import type { MonitorMutations_SceneUpdate_Mutation } from './__generated__/MonitorMutations_SceneUpdate_Mutation.graphql';

export const MonitorSceneConnectionAddMutation = graphql`
  mutation MonitorMutations_SceneConnectionAdd_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String
    $channel_ids: [String!]!
    $timestamp: String!
    $name: String
    $isFavorite: Boolean
    $anchorTimeStamp: String
    $connections: [ID!]!
  ) {
    createOrUpdateVmsPlusRecentChannels(
      input: {
        appId: $app_id
        customerId: $customer_id
        id: $id
        timestamp: $timestamp
        channelIDs: $channel_ids
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
      }
    ) {
      recentEdge @prependEdge(connections: $connections) {
        node {
          id
          name
          channelIDs
          timestamp
          isFavorite
          anchorTimeStamp
        }
      }
    }
  }
`;

export const MonitorSceneConnectionRemoveMutation = graphql`
  mutation MonitorMutations_SceneConnectionRemove_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String
    $channel_ids: [String!]!
    $timestamp: String!
    $name: String
    $isFavorite: Boolean
    $anchorTimeStamp: String
    $connections: [ID!]!
  ) {
    createOrUpdateVmsPlusRecentChannels(
      input: {
        appId: $app_id
        customerId: $customer_id
        id: $id
        timestamp: $timestamp
        channelIDs: $channel_ids
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
      }
    ) {
      recentEdge {
        node {
          id @deleteEdge(connections: $connections)
          name
          channelIDs
          timestamp
          isFavorite
          anchorTimeStamp
        }
      }
    }
  }
`;

export const MonitorSceneUpdateMutation = graphql`
  mutation MonitorMutations_SceneUpdate_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $channel_ids: [String!]!
    $timestamp: String!
    $id: String!
    $name: String
    $isFavorite: Boolean
    $anchorTimeStamp: String
  ) {
    createOrUpdateVmsPlusRecentChannels(
      input: {
        appId: $app_id
        customerId: $customer_id
        channelIDs: $channel_ids
        id: $id
        timestamp: $timestamp
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
      }
    ) {
      recentEdge {
        node {
          id
          name
          channelIDs
          timestamp
          isFavorite
          anchorTimeStamp
        }
      }
    }
  }
`;

export const MonitorSceneRemoveMutation = graphql`
  mutation MonitorMutations_SceneRemove_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String!
    $connections: [ID!]!
  ) {
    deleteVmsPlusRecentChannels(
      input: { appId: $app_id, customerId: $customer_id, id: $id }
    ) {
      deleted {
        id @deleteEdge(connections: $connections)
      }
    }
  }
`;

export const useSceneMarkingFavoriteActions = ({
  scene,
  sceneConnectionData,
  appId,
}: {
  scene: SceneFragment$data;
  sceneConnectionData: {
    id: string;
    key: string;
  };
  appId: number;
}) => {
  const customerId = getCurrentCustomerID();
  const [commitConnectionAddSceneMutation] =
    useMutation<MonitorMutations_SceneConnectionAdd_Mutation>(
      MonitorSceneConnectionAddMutation,
    );

  const [commitConnectionRemoveSceneMutation] =
    useMutation<MonitorMutations_SceneConnectionRemove_Mutation>(
      MonitorSceneConnectionRemoveMutation,
    );

  const markAsFavorite = () => {
    const connectionId = ConnectionHandler.getConnectionID(
      sceneConnectionData.id,
      sceneConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: true } },
    );

    commitConnectionAddSceneMutation({
      variables: {
        app_id: appId,
        customer_id: customerId,
        id: scene.id,
        connections: [connectionId],
        channel_ids: scene.channelIDs as string[],
        name: scene.name,
        anchorTimeStamp: scene.anchorTimeStamp,
        timestamp: getCurrentTimestamp() + '',
        isFavorite: true,
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: scene.id,
              channelIDs: scene.channelIDs as string[],
              name: scene.name,
              anchorTimeStamp: scene.anchorTimeStamp,
              timestamp: getCurrentTimestamp() + '',
              isFavorite: true,
            },
          },
        },
      },
    });
  };

  const unMarkAsFavorite = () => {
    const connectionId = ConnectionHandler.getConnectionID(
      sceneConnectionData.id,
      sceneConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: true } },
    );

    commitConnectionRemoveSceneMutation({
      variables: {
        app_id: appId,
        customer_id: customerId,
        id: scene.id,
        connections: [connectionId],
        channel_ids: scene.channelIDs as string[],
        name: scene.name,
        anchorTimeStamp: scene.anchorTimeStamp,
        timestamp: getCurrentTimestamp() + '',
        isFavorite: false,
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: scene.id,
              channelIDs: scene.channelIDs as string[],
              name: scene.name,
              anchorTimeStamp: scene.anchorTimeStamp,
              timestamp: getCurrentTimestamp() + '',
              isFavorite: false,
            },
          },
        },
      },
    });
  };

  return {
    markAsFavorite,
    unMarkAsFavorite,
  };
};

export const useSceneAddActions = ({
  sceneConnectionData,
  appId,
}: {
  sceneConnectionData: {
    id: string;
    key: string;
  };
  appId: number;
}) => {
  const customerId = getCurrentCustomerID();
  const [commitConnectionAddSceneMutation] =
    useMutation<MonitorMutations_SceneConnectionAdd_Mutation>(
      MonitorSceneConnectionAddMutation,
    );
  const addScene = ({
    channelIds,
    name,
    anchorTimeStamp,
    onSceneAdded,
  }: {
    channelIds: string[];
    name: string;
    anchorTimeStamp: string;
    onSceneAdded?: (
      response: MonitorMutations_SceneConnectionAdd_Mutation['response'],
    ) => void;
  }) => {
    const connectionId = ConnectionHandler.getConnectionID(
      sceneConnectionData.id,
      sceneConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: false } },
    );
    const currentTimestamp = getCurrentTimestamp();

    commitConnectionAddSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        channel_ids: channelIds,
        timestamp: currentTimestamp + '',
        anchorTimeStamp,
        connections: [connectionId],
        name,
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: channelIds.join('-') + '-' + currentTimestamp,
              channelIDs: channelIds,
              timestamp: getCurrentTimestamp() + '',
              anchorTimeStamp,
              isFavorite: false,
              name,
            },
          },
        },
      },
      onCompleted: onSceneAdded,
    });
  };

  return { addScene };
};

export const useSceneRemoveActions = ({
  scene,
  sceneConnectionData,
  appId,
}: {
  scene: SceneFragment$data;
  sceneConnectionData: {
    id: string;
    key: string;
  };
  appId: number;
}) => {
  const customerId = getCurrentCustomerID();
  const [commitRemoveSceneMutation] =
    useMutation<MonitorMutations_SceneRemove_Mutation>(
      MonitorSceneRemoveMutation,
    );

  const removeScene = () => {
    const connectionOnlyFavoritesScenes = ConnectionHandler.getConnectionID(
      sceneConnectionData.id,
      sceneConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: true } },
    );

    const connectionAllScenes = ConnectionHandler.getConnectionID(
      sceneConnectionData.id,
      sceneConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: false } },
    );

    commitRemoveSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        connections: [connectionOnlyFavoritesScenes, connectionAllScenes],
      },
      optimisticResponse: {
        deleteVmsPlusRecentChannels: {
          deleted: {
            id: scene.id,
          },
        },
      },
    });
  };

  return { removeScene };
};

export const useSceneUpdateActions = ({
  scene,
  appId,
}: {
  scene: SceneFragment$data;
  appId: number;
}) => {
  const [commitUpdateSceneMutation] =
    useMutation<MonitorMutations_SceneUpdate_Mutation>(
      MonitorSceneUpdateMutation,
    );

  const updateSceneName = (name: string) => {
    commitUpdateSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        channel_ids: scene.channelIDs as string[],
        name,
        anchorTimeStamp: scene.anchorTimeStamp,
        timestamp: getCurrentTimestamp() + '',
        isFavorite: scene.isFavorite,
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: scene.id,
              channelIDs: scene.channelIDs as string[],
              name,
              anchorTimeStamp: scene.anchorTimeStamp,
              timestamp: getCurrentTimestamp() + '',
              isFavorite: scene.isFavorite,
            },
          },
        },
      },
    });
  };

  const updateSceneNameAndChannels = (
    name: string,
    channelIds: string[],
    anchorTimeStamp: string,
  ) => {
    commitUpdateSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        channel_ids: channelIds,
        name,
        anchorTimeStamp: anchorTimeStamp,
        timestamp: getCurrentTimestamp() + '',
        isFavorite: scene.isFavorite,
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: scene.id,
              channelIDs: channelIds,
              name,
              anchorTimeStamp: anchorTimeStamp,
              timestamp: getCurrentTimestamp() + '',
              isFavorite: scene.isFavorite,
            },
          },
        },
      },
    });
  };

  return { updateSceneName, updateSceneNameAndChannels };
};
