import { WarningOutlined } from '@ant-design/icons';
import { Button, Image, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { connect, Link } from 'umi';

import CamDefault from '@/assets/cam-image-default.png';
import ChannelImageStream from '@/components/channel-image-stream';
import ChannelSettings from '@/components/ChannelSettings';
import ChannelMedia from '@/pages/locations/components/channel-media';
import { META_VMS_PLUGIN_ID } from '@/utils/utils';
import styles from './style.less';

import type {
  CH_GRP_TYPE,
  CH_TYPE,
  LOC_TYPE,
  MEDIA_TYPE,
} from '@/types/location';

const ObserverComponent = ({
  isStreamSupported = false,
  ChannelID = -1,
  preload = false,
}: {
  isStreamSupported: boolean;
  ChannelID: number;
  preload: boolean;
}) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    skip: preload,
  });

  return (
    <div ref={ref} className="df-tile-image-stream">
      {ChannelID != -1 && isStreamSupported && (inView || preload) && (
        <ChannelImageStream channelID={ChannelID} />
      )}
    </div>
  );
};

type MyProps = {
  channelID: number;
  showTime?: boolean;
  showProcessingText?: boolean;
  showImagePreview?: boolean;
  hideFooter?: boolean;
  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  media?: MEDIA_TYPE;
  preload?: boolean;
  noLink?: boolean;
};

type MyState = {};

// @ts-expect-error
@connect(({ locations }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  media: locations.media,
}))
class ChannelTile extends React.Component<MyProps, MyState> {
  render() {
    const {
      channelID,
      showTime,
      showProcessingText = true,
      showImagePreview = true,
      hideFooter,
      loc,
      ch,
      ch_grp,
      media,
      noLink = false,
      preload = false,
    } = this.props;

    const channel = ch?.byId[channelID] || null;
    if (!channel) return <div className="df-center">Channel not found...</div>;

    let isStreamSupported = false;
    const location = loc?.byId[channel.ProjectID || -1] || null;
    if (location) {
      isStreamSupported = _.get(location, 'VMSPluginID') === META_VMS_PLUGIN_ID;
    }

    const mediaID = _.max(channel.MediaIDs);
    const channelMedia = _.get(media, `byId[${mediaID}]`, null);

    let title = null;
    if (channel.ChannelGroupID) {
      title = _.get(ch_grp, `byId[${channel.ChannelGroupID}].Name`, null);
    } else if (location) {
      title = location.Name != '' ? location.Name : null;
    }

    // note that MonitorStatus might not be available in all views
    const inactive =
      _.get(channel, ['RTSPconfig', 'monitor_status'], 'NULL') === 'inactive';

    return (
      <div
        id={`channel-tile-${channel.ID}`}
        className={`${styles['channel-tile']} df-tile-container`}>
        <div
          className={`df-tile-content ${
            inactive ? 'df-tile-container-disabled' : ''
          }`}>
          {inactive ? (
            <div className={`${styles['inactive-label']} df-tile-badge`}>
              <ChannelSettings channelID={channel?.ID}>
                <WarningOutlined />
                &nbsp;&nbsp;Inactive
              </ChannelSettings>
            </div>
          ) : null}
          <>
            {/* CONTENT BODY */}
            <Link
              className="df-tile-body"
              to={
                noLink
                  ? null
                  : `/locations/${channel.ProjectID}/channels/${channel.ID}`
              }>
              {showImagePreview && (
                <ObserverComponent
                  isStreamSupported={isStreamSupported}
                  ChannelID={channel.ID}
                  preload={preload}
                />
              )}
              {_.get(channelMedia, 'Thumbnail') ? (
                // UPDATE COMPONENT
                <ChannelMedia
                  // @ts-expect-error
                  showTime={showTime}
                  showProcessingText={showProcessingText}
                  key={channelMedia.UploadID}
                  media={channelMedia}
                  style={{
                    borderRadius: '8px 8px 0 0',
                  }}
                />
              ) : (
                <>
                  <Image src={CamDefault} preview={false} />
                  {_.get(channelMedia, 'ProcessingErrCode') ? (
                    <div className={styles['video-duration']}>
                      <Tooltip
                        placement="bottomLeft"
                        title={
                          <span>
                            {_.get(
                              channelMedia,
                              'ProcessingErrDesc',
                              'Error in Processing',
                            )}
                          </span>
                        }>
                        <WarningOutlined /> Error in Processing
                      </Tooltip>
                      <Button
                        size="small"
                        type="text"
                        className={styles['contact-btn']}
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = 'mailto:info@dragonfruit.ai';
                        }}>
                        Contact Us
                      </Button>
                    </div>
                  ) : null}
                </>
              )}
            </Link>

            {/* CONTENT FOOTER */}
            {!hideFooter && (
              <Link
                className="df-tile-footer"
                to={
                  noLink
                    ? null
                    : `/locations/${channel.ProjectID}/channels/${channel.ID}`
                }>
                {title && (
                  <div className={styles['channel-group-name']} title={title}>
                    {title}
                  </div>
                )}
                <div className={styles['channel-name']} title={channel.Name}>
                  {channel.Name}
                </div>
              </Link>
            )}
          </>
        </div>
      </div>
    );
  }
}
export default ChannelTile;
