import { Typography } from 'antd';
import styled from 'styled-components';

export const TabPill = styled(Typography.Text)<{ active?: boolean }>(
  ({ theme, active }) => `
  padding: 5px 10px;
  color: ${active ? theme.token?.colorPrimary : theme.token?.colorTextBase};
  font-size: 14px;
  font-weight: ${active ? '700' : '500'};
  background-color: ${active ? '#F0E4FA' : 'inherit'};
  cursor: pointer;
  border-radius: ${theme.token?.borderRadiusSM}px;
`,
);
