import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const ActiveSceneContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 20px;
`;

export const StyledSider = styled(Sider)(
  ({ theme }) => `
  background: ${theme.components?.Layout?.siderBg} !important;

  @media (max-width: ${theme.token?.screenSM}px) {
    position: absolute !important;
    z-index: 1;
    left: 10px;
    top: 10px;
  }
`,
);
