import LoadingSpinner from '@/components/LoadingSpinner';
import { withDfMediaQuery } from '@/utils/dfMediaQuery';
import { getCurrentCustomerID } from '@/utils/utils';
import { Typography } from 'antd';
import React, { createContext, useMemo, useState } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { useLocation, useSelector } from 'umi';
import { SceneFragment } from './components/Scene';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from './components/Scene/__generated__/SceneFragment.graphql';
import { MonitorAppId } from './constants';
import type {
  MonitorContextQuery,
  MonitorContextQuery$data,
} from './__generated__/MonitorContextQuery.graphql';

const { Text, Title } = Typography;

// Define the initial state of the context
interface MonitorContextState {
  monitorAppQueryData: MonitorContextQuery$data;
  appId: number;
  customerId: number;
  setSelectedChannels: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
  isSidebarHidden?: boolean;
  selectedChannels?: string[];
  toggleSidebarVisibility: () => void;
  selectedSceneRef?: SceneFragment$key;
  setSelectedSceneRef: (sceneRef?: SceneFragment$key) => void;
  passedChannelIds?: string[];
  isSceneViewingMode?: boolean;
  newChannelsSelected?: boolean;
  isTimelineViewingMode?: boolean;
  selectedSceneData?: SceneFragment$data | null;
  subHeaderAuxControls?: JSX.Element;
  setSubHeaderAuxControls: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
}

// Create the context
export const MonitorContext = createContext<MonitorContextState | undefined>(
  undefined,
);

interface MonitorProviderProps {
  children: JSX.Element;
}

const MonitorQuery = graphql`
  query MonitorContextQuery($app_id: Int!, $customer_id: Int!) {
    vmsPlusConfig(appId: $app_id, customerId: $customer_id) {
      scenesData {
        __id
        ...TeamScenesFragment
          @arguments(
            app_id: $app_id
            customer_id: $customer_id
            only_favorites: false
          )
      }
    }
  }
`;

export const MonitorProvider = withDfMediaQuery(
  ({ children, isMobile }: MonitorProviderProps) => {
    const location = useLocation();
    const customerId = useMemo(() => getCurrentCustomerID(), []);
    const passedChannelIds = useMemo(
      () => new URLSearchParams(location.search).getAll('channelIds'),

      [location.search],
    );
    // Define the state variables and functions you want to expose in the context
    const [isSidebarHidden, setIsSidebarHidden] = useState(!!isMobile);
    const [selectedChannels, setSelectedChannels] = useState<
      string[] | undefined
    >(passedChannelIds ?? []);
    const [selectedSceneRef, setSelectedSceneRef] =
      useState<SceneFragment$key>();
    const [subHeaderAuxControls, setSubHeaderAuxControls] =
      useState<JSX.Element>();

    const monitorAppQueryData = useLazyLoadQuery<MonitorContextQuery>(
      MonitorQuery,
      {
        app_id: MonitorAppId,
        customer_id: customerId,
      },
    );
    const allApps = useSelector((state: any) => state.apps);
    const allAppsDataLoading = useSelector(
      (state: any) => state.loading.effects['apps/fetchAllApps'],
    );
    const isMonitorAppInstalled =
      allApps.all.filter((app: any) => app.AppID === MonitorAppId).length > 0;

    const selectedSceneData = useFragment(SceneFragment, selectedSceneRef);

    const newChannelsSelected =
      (selectedSceneData?.channelIDs?.length ?? 0) !==
      (selectedChannels?.length ?? 0);

    const isSceneViewingMode =
      !!selectedChannels?.length && !newChannelsSelected;
    const isTimelineViewingMode = !!selectedChannels?.length;

    const toggleSidebarVisibility = () => {
      setIsSidebarHidden((prev) => !prev);
    };

    if (allAppsDataLoading) {
      return <LoadingSpinner />;
    }

    if (!isMonitorAppInstalled) {
      return (
        <div
          style={{
            marginTop: '64px',
            textAlign: 'center',
          }}>
          <Title level={3}>App not installed</Title>
          <Text strong>
            Ask your Account Manager for Live View App access for this
            functionality
          </Text>
        </div>
      );
    }

    return (
      <MonitorContext.Provider
        value={{
          monitorAppQueryData,
          appId: MonitorAppId,
          customerId,
          isSidebarHidden,
          toggleSidebarVisibility,
          setSelectedChannels,
          selectedChannels,
          setSelectedSceneRef,
          selectedSceneRef,
          passedChannelIds,
          isSceneViewingMode,
          newChannelsSelected,
          isTimelineViewingMode,
          selectedSceneData,
          subHeaderAuxControls,
          setSubHeaderAuxControls,
        }}>
        {children}
      </MonitorContext.Provider>
    );
  },
);

export const useMonitorContext = () => {
  const context = React.useContext(MonitorContext);
  if (context === undefined) {
    throw new Error('useVMSPlusContext must be used within a VMSPlusProvider');
  }
  return context;
};
