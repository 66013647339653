import LineDivider from '@/components/LineDivider';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import AccessRules from '@/pages/account/access-rules';
import UsersPermissions from '@/pages/account/users-permissions';
import { Tabs } from 'antd';
import { Suspense } from 'react';
import {
  commitLocalUpdate,
  graphql,
  useLazyLoadQuery,
  useRefetchableFragment,
  useRelayEnvironment,
} from 'react-relay';
import { useSelector } from 'umi';
import { APP_ID, CAPABILITIES, CAPABILITIES_LABEL_MAP } from './constants';
import {
  FleetManagerProvider,
  useFleetManagerContext,
} from './FleetManagerContext';
import Labels from './Labels';
import Monitoring from './monitoring';
import Sites from './Sites';
import { FleetManagerAppQuery } from './__generated__/FleetManagerAppQuery.graphql';
import { FleetManagerApp_labels$key } from './__generated__/FleetManagerApp_labels.graphql';
import { FleetManagerApp_sites$key } from './__generated__/FleetManagerApp_sites.graphql';
import { LabelsRefetchQuery } from './__generated__/LabelsRefetchQuery.graphql';
import { SitesRefetchQuery } from './__generated__/SitesRefetchQuery.graphql';

const FleetManagerQuery = graphql`
  query FleetManagerAppQuery($app_id: Int!, $customer_id: Int!) {
    ...FleetManagerApp_labels
      @arguments(app_id: $app_id, customer_id: $customer_id)
    ...FleetManagerApp_sites
      @arguments(app_id: $app_id, customer_id: $customer_id)
  }
`;

const FleetManagerSites = graphql`
  fragment FleetManagerApp_sites on Query
  @refetchable(queryName: "SitesRefetchQuery")
  @argumentDefinitions(
    app_id: { type: "Int!" }
    customer_id: { type: "Int!" }
    first: { type: "Int", defaultValue: 10000 }
    after: { type: "String" }
  ) {
    sites(
      appId: $app_id
      customerId: $customer_id
      sort: [NAME_ASC]
      first: $first
      after: $after
    ) @connection(key: "FleetManagerApp_sites") {
      ...Labels_sites
      ...Sites_sites
      edges {
        node {
          id
        }
      }
    }
  }
`;

const FleetManagerLabels = graphql`
  fragment FleetManagerApp_labels on Query
  @refetchable(queryName: "LabelsRefetchQuery")
  @argumentDefinitions(
    app_id: { type: "Int!" }
    customer_id: { type: "Int!" }
    first: { type: "Int", defaultValue: 10000 }
    after: { type: "String" }
  ) {
    labels(
      appId: $app_id
      customerId: $customer_id
      sort: [NAME_ASC]
      first: $first
      after: $after
    ) @connection(key: "FleetManagerApp_labels") {
      ...Labels_labels
      ...Sites_labels
      #See https://github.com/facebook/relay/issues/1983
      edges {
        node {
          id
        }
      }
    }
  }
`;

const FleetManagerApp = () => {
  const appConfig = useSelector(
    (state) => state.apps.all.filter((a) => a.AppID == APP_ID)[0],
  );

  const capabilities = _.get(appConfig, 'capabilities', []);
  let tabsToShow = CAPABILITIES;

  if (capabilities.indexOf('*') == -1) {
    tabsToShow = tabsToShow.filter((tab) => capabilities.indexOf(tab) !== -1);
  }

  const { appId, customerId } = useFleetManagerContext();
  const queryData = useLazyLoadQuery<FleetManagerAppQuery>(FleetManagerQuery, {
    app_id: appId,
    customer_id: customerId,
  });
  const [sitesData, refreshSitesData] = useRefetchableFragment<
    SitesRefetchQuery,
    FleetManagerApp_sites$key
  >(FleetManagerSites, queryData);
  const [labelsData, refreshLabelsData] = useRefetchableFragment<
    LabelsRefetchQuery,
    FleetManagerApp_labels$key
  >(FleetManagerLabels, queryData);
  const environment = useRelayEnvironment();

  const refreshData = () => {
    commitLocalUpdate(environment, (store) => {
      store.invalidateStore();
    });
    refreshSitesData(
      { app_id: appId, customer_id: customerId },
      { fetchPolicy: 'network-only' },
    );
    refreshLabelsData(
      { app_id: appId, customer_id: customerId },
      { fetchPolicy: 'network-only' },
    );
  };

  return (
    <div>
      <PageHeader title="Fleet Manager" />
      <div style={{ paddingRight: 16 }}>
        <Tabs>
          {tabsToShow.includes('sites-and-labels') ? (
            <Tabs.TabPane
              key="sites-and-labels"
              tab={CAPABILITIES_LABEL_MAP['sites-and-labels']}>
              <div style={{ marginBottom: '16px' }}>
                <Labels
                  labelConnectionRef={labelsData.labels}
                  siteConnectionRef={sitesData.sites}
                />
                <br />
                <br />
                <Sites
                  labelConnectionRef={labelsData.labels}
                  siteConnectionRef={sitesData.sites}
                  refreshData={refreshData}
                />
              </div>
            </Tabs.TabPane>
          ) : null}
          {tabsToShow.includes('users-and-roles') ? (
            <Tabs.TabPane key="users" tab="Users & Roles">
              <UsersPermissions
                hideRoleSelection
                hidePageHeader
                alwayShowAddUserOption
              />
              <LineDivider margin="16px 0" />
              <AccessRules />
            </Tabs.TabPane>
          ) : null}
          {tabsToShow.includes('monitoring') ? (
            <Tabs.TabPane key="monitoring" tab="Monitoring">
              <Monitoring />
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
};

const FleetManagerAppWithSuspense = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <FleetManagerProvider>
        <FleetManagerApp />
      </FleetManagerProvider>
    </Suspense>
  );
};

FleetManagerAppWithSuspense.CAPABILITIES = CAPABILITIES;
FleetManagerAppWithSuspense.CAPABILITIES_LABEL_MAP = CAPABILITIES_LABEL_MAP;

export default FleetManagerAppWithSuspense;
