import { LabelSelector } from '@/components/LabelAndSiteSelector';
import LoadingSpinner from '@/components/LoadingSpinner';
import { LocationModalState } from '@/models/location_map';
import { Button, Form, Input, Modal, Popconfirm, Select } from 'antd';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { useSelector } from 'umi';
import { useFleetManagerContext } from '../FleetManagerContext';
import Address, { isAddressEmpty } from '../FormElements/Address';
import Contacts from '../FormElements/Contacts';
import Markdown from '../FormElements/Markdown';
import TimezoneSelect from '../FormElements/TimezoneSelect';
import { useCreateSiteMutation } from '../mutations/CreateSiteMutation';
import { useDeleteSiteMutation } from '../mutations/DeleteSiteMutation';
import { useUpdateSiteMutation } from '../mutations/UpdateSiteMutation';
import { CreateSiteInput } from '../mutations/__generated__/CreateSiteMutation.graphql';
import styles from './style.less';
import type { CreateUpdateSite_labels$key } from './__generated__/CreateUpdateSite_labels.graphql';
import type { CreateUpdateSite_SiteNodeQuery } from './__generated__/CreateUpdateSite_SiteNodeQuery.graphql';

type CreateUpdateSiteProps = {
  siteId?: string;
  siteConnectionId: string;
  labelConnectionRef: CreateUpdateSite_labels$key;
  onClose: () => void;
};

const labelConnectionFragment = graphql`
  fragment CreateUpdateSite_labels on LabelConnection {
    edges {
      node {
        Name @required(action: LOG)
        LabelID @required(action: LOG)
        Color
      }
    }
  }
`;

const siteNodeQuery = graphql`
  query CreateUpdateSite_SiteNodeQuery($siteId: ID!) {
    node(id: $siteId) {
      ... on Site {
        ...sites_siteDetails @relay(mask: false)
      }
    }
  }
`;

const CreateUpdateSite: React.FC<CreateUpdateSiteProps> = ({
  onClose,
  siteId: siteNodeId,
  labelConnectionRef,
  siteConnectionId,
}) => {
  const [form] = Form.useForm();
  const { customerId } = useFleetManagerContext();
  const [isCreating, createSite] = useCreateSiteMutation(siteConnectionId);
  const [isUpdating, updateSite] = useUpdateSiteMutation();
  const [isDeleting, deleteSite] = useDeleteSiteMutation(
    siteConnectionId,
    customerId,
  );
  const locations = useSelector(
    (state) => state['locations']['all'] as LocationModalState['all'],
  );

  const siteData = siteNodeId
    ? //eslint-disable-next-line react-hooks/rules-of-hooks
      useLazyLoadQuery<CreateUpdateSite_SiteNodeQuery>(siteNodeQuery, {
        siteId: siteNodeId,
      })
    : null;

  const labels = useFragment(
    labelConnectionFragment,
    labelConnectionRef,
  )?.edges?.map((edge) => edge?.node);

  const siteId: number | null = siteData?.node?.SiteID
    ? parseInt(siteData?.node?.SiteID)
    : null;

  const initialValues = useMemo(() => {
    if (siteData?.node) {
      const {
        Name,
        ExternalID,
        Labels,
        Timezone,
        AddressInfo,
        ShippingAddressInfo,
        Contacts: ContactsInfo,
        EscalationPath,
        Projects,
      } = siteData.node;

      return {
        name: Name || '',
        externalId: ExternalID || '',
        timezone: Timezone || '',
        labelIds: Labels?.map((label) => label?.LabelID) || [],
        address: AddressInfo
          ? {
              street: AddressInfo.Street || '',
              city: AddressInfo.City || '',
              state: AddressInfo.State || '',
              country: AddressInfo.Country || '',
            }
          : {},
        shippingAddress: ShippingAddressInfo
          ? {
              street: ShippingAddressInfo.Street || '',
              city: ShippingAddressInfo.City || '',
              state: ShippingAddressInfo.State || '',
              country: ShippingAddressInfo.Country || '',
            }
          : {},
        contacts: ContactsInfo || [],
        escalationPath: EscalationPath || '',
        projectIds: Projects?.edges?.map(
          (edge) => edge?.node?.ProjectID && parseInt(edge?.node?.ProjectID),
        ),
      };
    }
    return {
      labelIds: [],
      contacts: [],
    };
  }, [siteData]);

  const handleSubmit = useCallback(() => {
    form.validateFields().then((values) => {
      const {
        name,
        externalId,
        labelIds,
        timezone,
        contacts,
        address,
        shippingAddress,
        escalationPath,
        projectIds,
      } = values;
      let input = {
        customerId,
        name,
        externalId,
        siteGroupIds: labelIds,
        timezone,
        contactDetails: contacts,
        addressObj: isAddressEmpty(address) ? null : address,
        shippingAddressObj: isAddressEmpty(shippingAddress)
          ? null
          : shippingAddress,
        escalationPath,
        projectIds,
      } as CreateSiteInput;

      if (siteId) {
        updateSite({ ...input, siteId }, () => {
          onClose();
        });
      } else {
        createSite(input, () => {
          onClose();
        });
      }
    });
  }, [form, createSite, updateSite, siteId, customerId, onClose]);

  const handleDelete = useCallback(() => {
    if (siteId) {
      deleteSite(siteId, () => {
        onClose();
      });
    }
  }, [siteId, deleteSite, onClose]);

  const formHtml = (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the name' }]}>
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        label="External ID"
        name="externalId"
        rules={[{ required: true, message: 'Please enter a value' }]}>
        <Input />
      </Form.Item>
      <TimezoneSelect title="Timezone" formKey="timezone" />
      <Form.Item name="labelIds" label="Site Groups">
        <LabelSelector labels={labels} />
      </Form.Item>
      <Form.Item label="Basestations" name="projectIds">
        <Select mode="multiple">
          {locations.map((loc) => (
            <Select.Option value={loc.ProjectID}>{loc.Name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Address title="Address" formKey="address" />
      <Address title="Shipping Address" formKey="shippingAddress" />
      <Form.Item name="contacts">
        <Contacts />
      </Form.Item>
      <Form.Item name="escalationPath">
        <Markdown title="Escalation Path" />
      </Form.Item>
    </Form>
  );

  const isError = siteNodeId && !siteId;

  return (
    <Modal
      title={siteId ? 'Update Site' : 'Create Site'}
      className={styles['modal']}
      width={400}
      open
      onOk={handleSubmit}
      okButtonProps={{
        loading: isCreating || isUpdating,
      }}
      onCancel={onClose}
      footer={
        isError
          ? null
          : [
              <Button key="submit" type="primary" onClick={handleSubmit}>
                Save
              </Button>,
              <Button key="cancel" onClick={onClose}>
                Cancel
              </Button>,
              siteId && (
                <Popconfirm
                  title="Are you sure you want to delete this site?"
                  onConfirm={handleDelete}
                  okText="Yes"
                  cancelText="No">
                  <Button key="delete" danger loading={isDeleting}>
                    Delete
                  </Button>
                </Popconfirm>
              ),
            ]
      }>
      {isError ? (
        <div>Something went wrong while loading the site details!</div>
      ) : (
        formHtml
      )}
    </Modal>
  );
};

type CreateUpdateSiteWithSuspenseProps = {
  siteId?: string;
  siteConnectionId: string;
  labelConnectionRef: CreateUpdateSite_labels$key;
  children: React.ReactNode;
};

const CreateUpdateSiteWithSuspense = (
  props: CreateUpdateSiteWithSuspenseProps,
) => {
  const [visible, setIsVisible] = useState(false);

  return (
    <Suspense fallback={<LoadingSpinner fontSize={12} />}>
      <>
        {visible && (
          <CreateUpdateSite
            {...props}
            onClose={() => {
              setIsVisible(false);
            }}
          />
        )}
        <span
          onClick={() => {
            setIsVisible(true);
          }}>
          {props.children}
        </span>
      </>
    </Suspense>
  );
};

export default CreateUpdateSiteWithSuspense;
