import styled from 'styled-components';

export const MonitorAppContainer = styled.div(
  ({ theme }) => `
  padding: 18px;
  background-color: ${theme.token?.colorBgLayout};
  margin-left: -16px;
  width: calc(100% + 16px); // extra 16px to compensate for the left padding of the #page-container from overrides.less
  height: 100%;
  overflow-y: scroll;
`,
);
