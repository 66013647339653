import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Empty } from 'antd';
import React, { Suspense, useMemo } from 'react';

import DataList from '@/components/DataList';
import LoadingSpinner from '@/components/LoadingSpinner';
import { ColoredText } from '@/utils/styled-components';
import { booleanTableFilterFunction } from '@/utils/utils';
import { graphql, useFragment } from 'react-relay';
import CreateUpdateSite from '../CreateUpdateSite';
import ImportData from '../ImportData';
import styles from './style.less';
import { Sites_labels$key } from './__generated__/Sites_labels.graphql';
import { Sites_sites$key } from './__generated__/Sites_sites.graphql';

const ITEMS_PER_PAGE = 10;

type SitesProps = {
  siteConnectionRef: Sites_sites$key;
  labelConnectionRef: Sites_labels$key;
  refreshData: () => void;
};

const SitesFragment = graphql`
  fragment Sites_sites on SiteConnection {
    __id
    edges {
      node {
        ...sites_siteSummary @relay(mask: false)
      }
    }
  }
`;

const LabelsFragment = graphql`
  fragment Sites_labels on LabelConnection {
    ...CreateUpdateSite_labels
  }
`;

const Sites: React.FC<SitesProps> = ({
  siteConnectionRef,
  labelConnectionRef,
  refreshData,
}) => {
  const sitesData = useFragment(SitesFragment, siteConnectionRef);
  const labelsData = useFragment(LabelsFragment, labelConnectionRef);

  const sites = sitesData?.edges.map((edge) => edge?.node) ?? [];
  const labelOptions = useMemo(() => {
    const names = new Set<string>();
    sites.forEach((site) => {
      site?.Labels?.forEach((label) => names.add(label?.Name as string));
    });
    return Array.from(names)
      .sort()
      .map((name) => ({ text: name, value: name }));
  }, [sites]);
  const booleanFilterOptions = [
    { text: 'Set', value: true },
    { text: 'Unset', value: false },
  ];

  const columns = [
    {
      title: 'Site',
      dataIndex: 'Name',
      key: 'Name',
      enableLocalFiltering: true,
      enableLocalSorting: true,
    },
    {
      title: 'ID',
      dataIndex: 'ExternalID',
      key: 'ExternalID',
      render: (id) => id || '-',
    },
    {
      title: 'Groups',
      key: 'Groups',
      render: (_, record) => {
        const labels = record.Labels;
        const labelsToRender = labels
          .slice(0, 3)
          .map((label) => {
            return <ColoredText color={label.Color}>{label.Name}</ColoredText>;
          })
          .reduce((prev, curr) => (prev ? [prev, ', ', curr] : [curr]), '');
        const additionalCount = labels.length - 3;

        return (
          <>
            {labelsToRender || '-'}
            {additionalCount > 0 && ` and ${additionalCount} more`}
          </>
        );
      },
      onFilter: (value, record) => {
        const labelNames = record.Labels.map((label) => label.Name);
        return labelNames.some((name) =>
          name.toLowerCase().includes(value.toLowerCase()),
        );
      },
      filterMultiple: true,
      filters: labelOptions,
    },
    {
      title: 'City',
      dataIndex: ['AddressInfo', 'City'],
      key: 'City',
      render: (city) => city || '-',
      enableLocalFiltering: true,
    },
    {
      title: 'State',
      dataIndex: ['AddressInfo', 'State'],
      key: 'State',
      render: (state) => state || '-',
      enableLocalFiltering: true,
    },
    {
      title: 'Country',
      dataIndex: ['AddressInfo', 'Country'],
      key: 'Country',
      render: (country) => country || '-',
      enableLocalFiltering: true,
    },
    {
      title: 'Ship Address',
      key: 'ShipAddress',
      dataIndex: ['HasShippingAddress'],
      render: (hasShippingAddress) =>
        hasShippingAddress ? (
          <CheckCircleOutlined className={styles['icon-check']} />
        ) : (
          <ExclamationCircleOutlined className={styles['icon-warning']} />
        ),
      enableLocalFiltering: true,
      filters: booleanFilterOptions,
      onFilter: booleanTableFilterFunction('HasShippingAddress'),
    },
    {
      title: 'Contacts',
      key: 'Contacts',
      dataIndex: ['HasContacts'],
      render: (hasContacts) =>
        hasContacts ? (
          <CheckCircleOutlined className={styles['icon-check']} />
        ) : (
          <ExclamationCircleOutlined className={styles['icon-warning']} />
        ),
      enableLocalFiltering: true,
      filters: booleanFilterOptions,
      onFilter: booleanTableFilterFunction('HasContacts'),
    },
    {
      title: 'Escalation',
      key: 'Escalation',
      dataIndex: ['HasEscalationPath'],
      render: (hasEscalationPath) =>
        hasEscalationPath ? (
          <CheckCircleOutlined className={styles['icon-check']} />
        ) : (
          <ExclamationCircleOutlined className={styles['icon-warning']} />
        ),
      enableLocalFiltering: true,
      filters: booleanFilterOptions,
      onFilter: booleanTableFilterFunction('HasEscalationPath'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <CreateUpdateSite
            siteId={record.id}
            siteConnectionId={sitesData?.__id}
            labelConnectionRef={labelsData}>
            <span className="df-link">Details</span>
          </CreateUpdateSite>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className={styles['header']}>
        <h3 style={{ margin: '0px' }}>Sites</h3>
        <div className={styles['actions']}>
          <CreateUpdateSite
            siteConnectionId={sitesData?.__id}
            labelConnectionRef={labelsData}>
            <Button type="primary">Add</Button>
          </CreateUpdateSite>
          <ImportData refreshData={refreshData}>
            <Button onClick={() => {}}>Import</Button>
          </ImportData>
        </div>
      </div>
      <DataList
        dataList={sites}
        size="small"
        columns={columns}
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data found"
            />
          ),
        }}
        pagination={{
          p_size: ITEMS_PER_PAGE,
          position: ['bottomLeft'],
        }}
      />
    </div>
  );
};

const SitesWrapper: React.FC<SitesProps> = (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <Sites {...props} />
  </Suspense>
);

export default SitesWrapper;
