import { Flex } from 'antd';
import {
  SceneCardContainer,
  SceneChannelThumbnailsContainer,
  SceneDetailsContainer,
  SceneDetailsTimestampText,
  SceneStarContainer,
  SceneStarredIcon,
  SceneTitleNameEditIcon,
  SceneUnStarredIcon,
  ScreenDetailsTitleText,
} from './styles';

import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useSelector } from 'umi';
import {
  useSceneMarkingFavoriteActions,
  useSceneRemoveActions,
  useSceneUpdateActions,
} from '../../MonitorMutations';
import { getSceneName } from '../../utils';
import { TileThumbnail } from './TileThumbnail';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from './__generated__/SceneFragment.graphql';

interface SceneProps {
  appId: number;
  customerId: number;
  sceneKey: SceneFragment$key;
  onSelect: (
    sceneRef: SceneFragment$key,
    sceneData: SceneFragment$data,
  ) => void;
  sceneConnectionData: {
    id: string;
    key: string;
  };
}

export const SceneFragment = graphql`
  fragment SceneFragment on VMSPlusRecentChannels {
    id
    name
    isFavorite
    channelIDs
    timestamp
    anchorTimeStamp
  }
`;

const Scene = ({
  appId,
  customerId,
  sceneKey,
  onSelect,
  sceneConnectionData,
}: SceneProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const scene = useFragment(SceneFragment, sceneKey);

  const { markAsFavorite, unMarkAsFavorite } = useSceneMarkingFavoriteActions({
    scene,
    sceneConnectionData,
    appId,
  });

  const { removeScene } = useSceneRemoveActions({
    scene,
    sceneConnectionData,
    appId,
  });

  const { updateSceneName } = useSceneUpdateActions({
    scene,
    appId,
  });

  const { ch } = useSelector((state) => ({
    // @ts-expect-error
    ch: state.locations.ch,
  }));

  const tileName = useMemo(
    () => scene.name ?? getSceneName(scene?.channelIDs as string[], ch),
    [scene, ch],
  );

  const handleTileClick = () => {
    onSelect(sceneKey, scene);
  };

  const handleStarClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    markAsFavorite();
  };

  const handleUnStarClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    unMarkAsFavorite();
  };

  const handleTileTitleChange = (text: string) => {
    if (text !== tileName) {
      updateSceneName(text);
    }
  };

  const handleMouseOverCardDetails = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeaveCardDetails = () => {
    setIsMouseOver(false);
  };

  return (
    <SceneCardContainer>
      <SceneStarContainer>
        {scene.isFavorite ? (
          <SceneStarredIcon onClick={handleUnStarClick} />
        ) : (
          <SceneUnStarredIcon onClick={handleStarClick} />
        )}
      </SceneStarContainer>
      <SceneChannelThumbnailsContainer onClick={handleTileClick}>
        <TileThumbnail
          appId={appId}
          customerId={customerId}
          channelIds={scene?.channelIDs.slice(0, 8) as string[]}
        />
      </SceneChannelThumbnailsContainer>
      <SceneDetailsContainer
        onMouseOver={handleMouseOverCardDetails}
        onMouseLeave={handleMouseLeaveCardDetails}>
        <Flex gap={16} align="baseline" justify="space-between">
          <ScreenDetailsTitleText
            editable={
              isMouseOver
                ? {
                    onChange: handleTileTitleChange,
                    icon: <SceneTitleNameEditIcon />,
                    autoSize: { maxRows: 2 },
                  }
                : false
            }
            ellipsis={{ rows: 2, expandable: false }}
            onClick={(e) => e?.stopPropagation()}>
            {tileName}
          </ScreenDetailsTitleText>
          {isMouseOver &&
            (scene.isFavorite ? (
              <Popconfirm
                title="Are you sure you want to delete the scene?"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  removeScene();
                }}
                okText="Delete"
                cancelText="Cancel"
                okType="danger"
                okButtonProps={{
                  type: 'primary',
                }}>
                <DeleteOutlined />
              </Popconfirm>
            ) : (
              <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  removeScene();
                }}
              />
            ))}
        </Flex>
        {scene?.timestamp && (
          <SceneDetailsTimestampText>
            Last updated {moment(Number(scene?.timestamp) * 1000).fromNow()}
          </SceneDetailsTimestampText>
        )}
      </SceneDetailsContainer>
    </SceneCardContainer>
  );
};

export { Scene };
