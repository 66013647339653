import { tableSorterFunction } from '@/utils/utils';
import { PaginationProps } from 'antd';
import { ColumnType } from 'antd/es/table';
import _ from 'lodash';

//Legend -
//Local tables - Pagination, filtering and sorting handled locally
//API powered/dynamic tables - expectation is that the parent handles all of this

//The typical use case here is to pass
//p_size or showSizeChanger for local tables
//p_number, p_size and total_pages for dynamic tables

export type DfColumnType<T> = ColumnType<T> & {
  enableLocalFiltering?: boolean;
  enableLocalSorting?: boolean;
  children?: DfColumnType<T>;
  excludeFromExport?: boolean;
  renderForExport?: ColumnType<T>['render'];
};

//TBD
export type DataRecord = any;
export type Column = DfColumnType<DataRecord>;

//This component supports 2 views - table(default) and a card
export type DataListProps = {
  //Decides how every card needs to be rendered in card view - skip if you simply want to display a table
  cardMap?: any;
  columns: Column[];
  dataList: DataRecord[];
  isLoading?: boolean;
  isHidden?: boolean;
  //Give your table a name if you want the filtering and pagination selections to persist in local storage
  //Todo - we need to incorporate this behavior for sorting too
  name?: string;
  //Define this if you do not want to use the default card <-> table switcher component
  ViewSwitcher?: React.ComponentType<any>;
  pagination?: PaginationProps;
  onChange?: any;
  isControlled?: boolean;
  exportedFilename?: string;
};

//Helper function for persisting selections
export const getPersistenceHelpers = (customerID: number, name?: string) => {
  return {
    saveAppliedFilters: (appliedFilters) => {
      if (!name) {
        return;
      }
      localStorage.setItem(
        `dataList.${name}.${customerID}.appliedFilters`,
        JSON.stringify(appliedFilters),
      );
    },
    getAppliedFilters: () => {
      if (!name) {
        return {};
      }
      const storedFilteredValuesString = localStorage.getItem(
        `dataList.${name}.${customerID}.appliedFilters`,
      );
      const storedFilteredValues = storedFilteredValuesString
        ? JSON.parse(storedFilteredValuesString)
        : {};
      return storedFilteredValues;
    },
    saveView: (view) => {
      if (!name) {
        return;
      }
      localStorage.setItem(`dataList.${name}.${customerID}.view`, view);
    },
    getView: () => {
      if (!name) {
        return null;
      }
      const storedView = localStorage.getItem(
        `dataList.${name}.${customerID}.view`,
      );
      return storedView || 'table';
    },
  };
};

export const getAppliedFiltersFromColumns = (columns: Column[]) => {
  let appliedFilters = {};
  columns.forEach((column) => {
    const filterValues = column.defaultFilteredValue || column.filteredValue;
    if (filterValues) {
      appliedFilters[column.key] = filterValues;
    }
  });
  return appliedFilters;
};

export const getAppliedSortFromColumns = (columns: Column[]) => {
  let appliedSort = {};
  columns.forEach((column) => {
    const sorteOrder = column.defaultSortOrder || column.sortOrder;
    if (sorteOrder) {
      appliedSort[column.key] = sorteOrder;
    }
  });
  return appliedSort;
};

export const _getFilterOptions = (column: Column, dataList: DataRecord[]) => {
  const uniqueValuesfromDataList = new Set();
  let columnEntries: string[] = [];

  if (column.dataIndex) {
    dataList.forEach((item) => {
      const value = _.get(item, column.dataIndex);
      if (Array.isArray(value)) {
        value.forEach((v) => uniqueValuesfromDataList.add(v));
      } else {
        uniqueValuesfromDataList.add(value);
      }
    });
    columnEntries = Array.from(uniqueValuesfromDataList) as string[];
  } else {
    columnEntries = Array.from(
      new Set(dataList.map((item) => column.render('', item))),
    ) as string[];
  }

  const columnOptions = columnEntries
    .filter((val) => !!val)
    .sort((a, b) => a.localeCompare(b))
    .map((value) => ({
      text: value,
      value,
    }));

  return [...columnOptions];
};

export const _getFilterPropsForColumn = (
  column: Column,
  dataList?: DataRecord[],
  savedFilter?: any,
) => {
  let filterProps: any = {};
  if (column.enableLocalFiltering) {
    filterProps['filterMultiple'] = true;
    if (!column.filters && dataList) {
      filterProps['filters'] = _getFilterOptions(column, dataList);
    }
    if (!column.onFilter) {
      let filterFunc = null;
      if (column.dataIndex) {
        filterFunc = (value, record) => {
          let data = _.get(record, column.dataIndex);
          if (Array.isArray(data)) {
            return data.includes(value);
          }
          return value === data;
        };
      } else {
        filterFunc = (value, record) => value === column.render('', record);
      }
      filterProps['onFilter'] = filterFunc;
    }
  }
  if (savedFilter) {
    filterProps['filteredValue'] = savedFilter;
  }
  return filterProps;
};

export const _getSorterPropsForColumn = (column: Column, sortOrder?: any) => {
  let sortProps = { sortOrder };
  if (column.enableLocalSorting && column.dataIndex) {
    sortProps = {
      ...sortProps,
      sorter: tableSorterFunction(
        Array.isArray(column.dataIndex) ? column.dataIndex : [column.dataIndex],
      ),
    };
  }
  return sortProps;
};

export const getFormattedColumns = (
  columns: Column[],
  dataList?: DataRecord[],
  savedFilters?: any,
  savedSort?: any,
) => {
  const processColumn = (column: Column) => {
    /*eslint-disable @typescript-eslint/no-unused-vars*/
    const {
      enableLocalFiltering,
      enableLocalSorting,
      ...standardAntdColumnProps
    } = column;
    let formattedColumn = { ...standardAntdColumnProps };
    formattedColumn = {
      ..._getFilterPropsForColumn(column, dataList, savedFilters[column.key]),
      ...formattedColumn,
      ...(Array.isArray(column['filters'])
        ? {
            filters: [...column['filters']].sort((a, b) =>
              (a.text?.toString() || '').localeCompare(b.text),
            ),
          }
        : {}),
    };
    formattedColumn = {
      ..._getSorterPropsForColumn(
        column,
        savedSort?.columnKey === column.key ? savedSort.order : null,
      ),
      ...formattedColumn,
    };
    if (Array.isArray(column['filters']) && column['filters'].length >= 10) {
      formattedColumn['filterSearch'] = true;
    }
    return formattedColumn;
  };

  const processColumns = (cols) => {
    return cols.map((column) => {
      if (column.children) {
        return {
          ...column,
          children: processColumns(column.children),
        };
      } else {
        return processColumn(column);
      }
    });
  };

  return processColumns(columns);
};

export const filterDataList = (dataList: DataRecord[], columns: Column[]) => {
  const filterRecord = (record: DataRecord, cols: Column[]) => {
    for (const column of cols) {
      if (column.children) {
        if (!filterRecord(record, column.children)) {
          return false;
        }
      } else {
        if (column.filteredValue?.length && column.onFilter) {
          if (
            !column.filteredValue.some((value) =>
              column.onFilter(value, record),
            )
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };

  return dataList.filter((record) => filterRecord(record, columns));
};

export const sortDataList = (
  dataList: DataRecord[],
  columns: Column[],
): DataRecord[] => {
  const getSorterFunction = (
    cols: Column[],
  ): ((a: DataRecord, b: DataRecord) => number) | null => {
    for (const column of cols) {
      if (column.children) {
        const childSorter = getSorterFunction(column.children);
        if (childSorter) return childSorter;
      } else if (column.sorter && column.sortOrder) {
        return (a: DataRecord, b: DataRecord) => {
          const result = column.sorter(a, b);
          return column.sortOrder === 'descend' ? -result : result;
        };
      }
    }
    return null;
  };

  const sorterFunction = getSorterFunction(columns);
  return sorterFunction ? [...dataList].sort(sorterFunction) : dataList;
};

export const paginateDataList = (
  dataList: any[],
  pageNumber: number,
  pageSize: number,
) => {
  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return dataList.slice(startIndex, endIndex);
};

const _stripHtmlTags = (str = '') => {
  return str.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
};

export const convertToCSV = (datalist: DataRecord[], columns: Column[]) => {
  const columnsToExport = columns.filter((col) => !col.excludeFromExport); // Only include columns that have a dataIndex
  const headers = columnsToExport.map((col) => col.title).join(',');

  const csvData = datalist.map((item) => {
    return columnsToExport
      .map((col, index) => {
        const renderFunc = col.renderForExport || col.render;
        let value = renderFunc
          ? _stripHtmlTags(
              renderFunc(item[col.dataIndex], item, index)?.toString(),
            )
          : item[col.dataIndex];
        return value !== undefined && value !== null
          ? value.toString().replace(/,/g, ';')
          : '';
      })
      .join(',');
  });
  return [headers, ...csvData].join('\n');
};
