import { Flex } from 'antd';
import { TabPill } from './styles';

export interface TabItem {
  title: string;
  onClick: () => void;
}

export interface TabsProps {
  items: TabItem[];
  activeTabIndex?: number;
}

const TabsExpanded = ({ items: tabs, activeTabIndex = 0 }: TabsProps) => {
  return (
    <Flex gap={20} align="center">
      {tabs.map(({ title, onClick }, index) => {
        return (
          <TabPill
            onClick={onClick}
            key={title}
            active={activeTabIndex == index}>
            {title}
          </TabPill>
        );
      })}
    </Flex>
  );
};

//Right now, look similar, but the idea is to make TabsCollapsible
//a dropdown like component that only shows the current tab
const TabsCollapsible = (props: TabsProps) => {
  return (
    <div style={{ padding: '20px' }}>
      <TabsExpanded {...props} />
    </div>
  );
};

export { TabsCollapsible, TabsExpanded };
