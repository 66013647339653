import { Typography } from 'antd';
import ChannelTileAndName from './ChannelTileAndName';
import { ChannelGroupContainer, ChannelGroupListContainer } from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';

type SelectedChannelListProps = {
  channelRefs: ChannelTileAndName_Channel$key[];
  removeChannel: (channelID: string | string[]) => void;
};

const SelectedChannelList = ({
  channelRefs,
  removeChannel,
}: SelectedChannelListProps) => {
  // @TODO - add some component here
  if (!channelRefs.length) {
    return <Typography.Text>No cameras selected</Typography.Text>;
  }

  return (
    <ChannelGroupContainer>
      <ChannelGroupListContainer>
        {channelRefs.map((channelRef, index) => (
          <ChannelTileAndName
            key={`channel-${index}`}
            tileFragmentRef={channelRef}
            siteName=""
            isSelected={true}
            onClick={(channelId) => removeChannel(channelId)}
          />
        ))}
      </ChannelGroupListContainer>
    </ChannelGroupContainer>
  );
};

export { SelectedChannelList };
