import { DragOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const SortableItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DragHandle = styled(DragOutlined)`
  cursor: grab;
  margin-right: 10px;
`;

export const ItemContent = styled.div`
  flex: 1;
`;
