import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

// ----- Status -----
export async function getCurrentStatus(
  payload: { date_range: string },
  app_id: number,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/current_stats`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getHistoricalStatus(
  payload: {
    date_range: string;
    group_by: string;
  },
  app_id: number,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/historical_stats`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

// ----- Alarms -----

export async function getAlarms(
  { p_number = 1, p_size = 25, site_ids, alarm_ids },
  app_id: number,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/alarms`;

  let params = {
    p_number,
    p_size,
  };
  if (site_ids) {
    params['site_ids'] = site_ids;
  }
  if (alarm_ids) {
    params['alarm_ids'] = alarm_ids;
  }

  return request(url, {
    params,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function toggleAlarmStatus(
  payload: {
    alarm_id: number;
    enabled: boolean;
  },
  app_id: number,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/status_update`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function setupAlarm(
  payload: {
    step: string;
    site_id: number;
    data: {
      name: string;
      timeframe: {
        days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
        timezone: string;
        time_range: {
          from: string;
          to: string;
        };
      };
      actions: [
        {
          type: string;
          to: string;
        },
        {
          type: string;
          to: string;
        },
      ];
      codes: [
        {
          name: string;
          enable: true;
          value: string;
          timeout: 300;
        },
        {
          name: string;
          enable: false;
          value: string;
          timeout: 300;
        },
      ];
      priority: 3;
      enabled: true;
      search_filter: {
        id: 3991;
      };
      sources: {
        channels: [
          {
            id: 54522;
          },
        ];
      };
      delivery: {
        type: string;
        threshold: {
          review_queue: false;
          run_frequency: 30;
          throttle_window: 600;
          event_time_buffer: 5;
          rolling_window: 120;
          type: string;
          metric: 0;
        };
      };
    };
  },
  app_id: number,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/setup`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteAlarm(
  payload: { alarm_id: number },
  app_id: number,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/delete`,
    {
      method: 'DELETE',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function duplicateAlarm(
  payload: { alarm_id: number },
  app_id: number,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/duplicate_alarm`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function submitAlarmCode(customerID: any, payload: any) {
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/rules/code`,
    {
      method: 'POST',
      data: payload,
    },
  );
}

export async function getIncidentFilters(app_id: number) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/incidents/filters`;
  return request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getIncidents(inputParams, app_id: number) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  let params = {};

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/incidents`;

  if (inputParams?.pagination?.p_number) {
    params['p_number'] = inputParams.pagination.p_number;
  }
  if (inputParams?.pagination?.p_size) {
    params['p_size'] = inputParams.pagination.p_size;
  }

  // Mapping from FE column key to BE expected parameter name happens here
  if (inputParams?.filters?.rule) {
    params['rule_ids'] = inputParams.filters.rule;
  }
  if (inputParams?.filters?.site_ids) {
    params['site_ids'] = inputParams.filters.site_ids;
  }
  if (inputParams?.filters?.priority) {
    params['priorities'] = inputParams.filters.priority;
  }
  if (inputParams?.filters?.owner) {
    params['owner_ids'] = inputParams.filters.owner;
  }
  if (inputParams?.filters?.ids) {
    params['ids'] = inputParams.filters.ids;
  }

  return request(url, {
    params,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateIncidentPriority(
  incident_id: any,
  app_id: number,
  priority?: number,
  reason?: string,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/update_priority`;

  return request(url, {
    method: 'POST',
    data: {
      incident_id,
      priority,
      reason,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getActivityLog(filters = {}, app_id: number) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  let params = {
    p_number: 1,
    p_size: 100,
  };

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/activity_log`;

  if (filters.p_number) {
    params['p_number'] = filters.p_number;
  }
  if (filters.p_size) {
    params['p_size'] = filters.p_size;
  }
  if (filters.type) {
    params['event_types'] = filters.type;
  }
  if (filters.user) {
    params['user_ids'] = filters.user;
  }
  if (filters.alarm) {
    params['alarm_ids'] = filters.alarm;
  }

  return request(url, {
    params,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
